import React from 'react';
   import MovieList from './MovieList';

   function App() {
     return (
       <div className="App">
         <MovieList />
       </div>
     );
   }

   export default App;

import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Search, PlusCircle, Menu, ExternalLink, Film, Edit, Trash2, LogIn, LogOut, Save , X, List ,  ChevronDown, ChevronRight , Globe, Info ,RefreshCw, Plus}  from 'lucide-react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, get, onValue, push, update, remove, set ,  child,   serverTimestamp } from 'firebase/database';
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { auth, database, app, analytics } from './firebaseConfig';
import GuestIDGenerator from './GuestId';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Notification from './Notification'; // Import the new Notification component
import { APP_RULES, IMPLEMENTATION_GUIDELINES, DATA_STRUCTURE } from './AppRules';
import { debounce } from 'lodash'; // Make sure to install lodash if you haven't already

const getLanguageFromCountry = (country) => {
  const commonLanguages = {
    'USA': 'English',
    'UK': 'English',
    'India': 'Hindi',
    'France': 'French',
    'Germany': 'German',
    'Italy': 'Italian',
    'Spain': 'Spanish',
    'Japan': 'Japanese',
    'China': 'Mandarin',
    'South Korea': 'Korean',
    // Add more countries and their common languages as needed
  };

  return commonLanguages[country] || 'Unknown';
};

const fetchStreamingLinks = async (title) => {
  await new Promise(resolve => setTimeout(resolve, 500));
  return [
    { provider: 'Netflix', url: `https://www.netflix.com/search?q=${encodeURIComponent(title)}` },
    { provider: 'Amazon', url: `https://www.amazon.com/s?k=${encodeURIComponent(title)}&i=instant-video` }
  ];
};

const sortMoviesByWeightDescending = (a, b) => b.weight - a.weight;

const getEnvVariable = (key) => {
  // if (import.meta && import.meta.env && import.meta.env[`VITE_${key}`]) {
  //   return import.meta.env[`VITE_${key}`];
  // }
  console.log(`REACT_APP_${key}`);
 
  if (process.env && process.env[`REACT_APP_${key}`]) {
    return process.env[`REACT_APP_${key}`];
  }
  
  console.log(`REACT_APP_${key}` || 'No environment variable found');
  return null;
};

const MovieList = () => {
  const [movies, setMovies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [streamingLinks, setStreamingLinks] = useState({});
  const newMovieRef = useRef(null);
  const [manualEntryMode, setManualEntryMode] = useState(false);
  const [manualMovieData, setManualMovieData] = useState({ title: '', year: '', country: '' });
  const [editingMovie, setEditingMovie] = useState(null);
  const [user, setUser] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [originalWeights, setOriginalWeights] = useState({});
  const [masterWeights, setMasterWeights] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [verificationAttempts, setVerificationAttempts] = useState(0);
  const [currentList, setCurrentList] = useState('master');
  const [userLists, setUserLists] = useState([]);
  const [listName, setListName] = useState('');
  const [isMyListsOpen, setIsMyListsOpen] = useState(false);
  const [publicLists, setPublicLists] = useState([]);
  const [isPublicListsOpen, setIsPublicListsOpen] = useState(false);
  const [sourceList, setSourceList] = useState('master');
  const [masterMovies, setMasterMovies] = useState([]);
  const [localChanges, setLocalChanges] = useState({});
  const [isGuest, setIsGuest] = useState(true);
  const [isFirebaseLoaded, setIsFirebaseLoaded] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userHandle, setUserHandle] = useState('');
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [notification, setNotification] = useState(null);
  const [guestChanges, setGuestChanges] = useState(null);
  const [authChecked, setAuthChecked] = useState(false);
  const [loadingState, setLoadingState] = useState('Initializing');
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [loadingStage, setLoadingStage] = useState('Initializing');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [masterList, setMasterList] = useState([]);
  const [isManualEntry, setIsManualEntry] = useState(false);
  const searchInputRef = useRef(null);
  const [editingField, setEditingField] = useState(null);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [pendingSearch, setPendingSearch] = useState(false);
  const [lastSuccessfulTerm, setLastSuccessfulTerm] = useState('');
  const [isListRefreshing, setIsListRefreshing] = useState(false);
  const [isAuthInitialized, setIsAuthInitialized] = useState(false);

  const MAX_VERIFICATION_ATTEMPTS = 3; // Define the constant for maximum verification attempts
  console.log('Movies state updated:', movies);

  //const ADMIN_EMAIL  = getEnvVariable('ADMIN_EMAIL') ;

  const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL || 'your-admin-email@example.com';

  const OMDB_API_KEY = getEnvVariable('REACT_APP_OMDB_API_KEY') || 'YOUR_FALLBACK_API_KEY';

 //const OMDB_API_KEY = getEnvVariable('REACT_APP_OMDB_API_KEY') || 'YOUR_FALLBACK_API_KEY';
  // isAdmin = user && user.email === ADMIN_EMAIL;

  const movieExistsInList = (movieToCheck, movieList) => {
    return movieList.some(movie => 
      movie.title.toLowerCase() === movieToCheck.title.toLowerCase() && 
      movie.year === movieToCheck.year
    );
  };

  const setAdminStatus = async (userId, isAdmin) => {
    try {
      const userRef = ref(database, `users/${userId}`);
      await update(userRef, { isAdmin: isAdmin });
      console.log(`Admin status for user ${userId} set to ${isAdmin}`);
    } catch (error) {
      console.error('Error setting admin status:', error);
    }
  };

  const debouncedHandleSearch = useCallback(
    debounce((term) => {
      if (term) {
        handleSearch(term);
      }
    }, 400), // Reduced from 600ms to 400ms for better responsiveness
    []
  );

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    
    if (!newSearchTerm) {
      setSuggestions([]);
      setHasSearched(false);
      setLastSuccessfulTerm('');
    } else {
      // Cancel any pending debounced search
      debouncedHandleSearch.cancel();
      // Start a new debounced search
      debouncedHandleSearch(newSearchTerm);
    }
  };
  
 
  const recalculateWeights = (movieList) => {
    return movieList.map((movie, index) => ({
      ...movie,
      weight: movieList.length - index
    }));
  };

  const handlePublicListNameChange = useCallback((listId, newName) => {
    const publicListRef = ref(database, `publicLists/${listId}`);
    update(publicListRef, { name: newName });
  }, [database]);
  
  const handlePublicListAuthorChange = useCallback((listId, newAuthorHandle) => {
    const publicListRef = ref(database, `publicLists/${listId}`);
    update(publicListRef, { authorHandle: newAuthorHandle });
  }, [database]);
  

  const updateLocalChanges = useCallback((listId, updatedMovies) => {
    const recalculatedMovies = recalculateWeights(updatedMovies);
    setLocalChanges(prev => ({
      ...prev,
      [listId]: recalculatedMovies
    }));
    setMovies(recalculatedMovies); // Add this line to update the UI immediately
    setHasUnsavedChanges(true);
    console.log('Local changes updated:', recalculatedMovies); // Add this log
  }, []);

  const updateUnsavedChanges = useCallback(() => {
    setHasUnsavedChanges(true);
  }, []);

  useEffect(() => {
    console.log('Current user:', user);
    console.log('Is admin:', isAdmin);
  }, [user, isAdmin]);
  
  useEffect(() => {
    const publicListsRef = ref(database, 'publicLists');
    const unsubscribe = onValue(publicListsRef, (snapshot) => {
      if (snapshot.exists()) {
        const listsData = snapshot.val();
        const lists = Object.entries(listsData).map(([id, list]) => ({
          id,
          name: list.name,
          authorHandle: list.authorHandle || 'Anonymous',
          owner: list.author
        }));
        setPublicLists(lists);
      } else {
        setPublicLists([]);
      }
    }, (error) => {
      console.error('Error fetching public lists:', error);
      setNotification({ message: 'Failed to fetch public lists. Please try again later.', type: 'error' });
    });
  
    // Cleanup function
    return () => unsubscribe();
  }, [database, setNotification]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [suggestions]); // This will run whenever suggestions change

  useEffect(() => {
    // Fetch the master list when the component mounts
    const masterListRef = ref(database, 'movies');
    onValue(masterListRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const movieList = Object.entries(data).map(([id, movie]) => ({
          id,
          ...movie,
        }));
        setMasterList(movieList);
      }
    });
  }, []);

  useEffect(() => {
    const masterListRef = ref(database, 'movies');
    const unsubscribe = onValue(masterListRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const movieList = Object.entries(data).map(([id, movie]) => ({
          id,
          ...movie,
        }));
        movieList.sort((a, b) => b.weight - a.weight);
        setMasterList(movieList);
      }
    });
  
    return () => unsubscribe();
  }, []);

  const AboutModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
          <h2 className="text-2xl font-bold mb-4">About Us</h2>
          <p className="mb-4">
            This website helps find the most popular movies by letting users create their own lists of favorites. It works like this: Users make a list with their favorite movie at the top, then their next favorite, and so on, until their least favorite movie is at the bottom. The movie at the top gets the most points, and the one at the bottom gets the fewest.
          </p>
          <p className="mb-4">
            We take everyone's lists and combine them to make a <strong>Master List</strong> of the most popular movies. A movie's score in the Master List is the total of all its points from all the lists. The more people like a movie, the higher it will rank in the Master List!
          </p>
          <button
            onClick={onClose}
            className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const getListName = useCallback((listId) => {
    if (listId === 'master') {
      return 'Master List';
    }
    if (listId.startsWith('public_')) {
      const publicList = publicLists.find(list => list.id === listId.replace('public_', ''));
      return publicList ? publicList.name : 'Public List';
    }
    const userList = userLists.find(list => list.id === listId);
    return userList ? userList.name : 'My List';
  }, [publicLists, userLists]);
  
  const clearLocalChanges = useCallback(() => {
    setLocalChanges(prevChanges => {
      const newChanges = { ...prevChanges };
      delete newChanges[currentList];
      return newChanges;
    });
    setMovies([]); // Reset movies state
  }, [currentList]);;

  const fetchMasterMovieList = useCallback(() => {
    console.log('Fetching master movie list...');
    setIsLoading(true);
    const masterMoviesRef = ref(database, 'movies');
    
    return onValue(masterMoviesRef, (snapshot) => {
      const data = snapshot.val();
      console.log('Master movie list data:', data);
      if (data) {
        const movieList = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value,
          masterWeight: value.weight,
        }));
        movieList.sort((a, b) => b.weight - a.weight);
        setMovies(movieList);
        setMasterMovies(movieList);
        setMasterList(movieList);
        setCurrentList('master');
      } else {
        setMovies([]);
        setMasterMovies([]);
        setMasterList([]);
      }
      setIsLoading(false);
      setIsInitialLoading(false);
    }, (error) => {
      console.error('Error fetching master list:', error);
      setNotification({ message: 'Failed to fetch master list. Please try again later.', type: 'error' });
      setIsLoading(false);
      setIsInitialLoading(false);
    });
  }, [database]);

  const fetchUserMovies = useCallback((userId) => {
    const userMoviesRef = ref(database, `users/${userId}/movies`);
    
    // Return the unsubscribe function
    return onValue(userMoviesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const movieList = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value,
          masterWeight: masterWeights[key] || 0,
        }));
        movieList.sort((a, b) => b.weight - a.weight);
        setMovies(movieList);
        
        // Store original weights
        const weights = {};
        movieList.forEach(movie => {
          weights[movie.id] = movie.weight;
        });
        setOriginalWeights(weights);
      } else {
        return fetchMasterMovieList();
      }
      setIsLoading(false);
    }, (error) => {
      console.error('Error fetching user movies:', error);
      return fetchMasterMovieList();
    });
  }, [masterWeights, fetchMasterMovieList]);

  const fetchMoviesFromFirebase = useCallback((path) => {
    console.log(`Fetching movies from path: ${path}`);
    setIsListRefreshing(true); // Use this instead of setIsLoading
    setNotification(null);
    const moviesRef = ref(database, path);
    
    return onValue(moviesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const movieList = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value,
        }));
        movieList.sort((a, b) => b.weight - a.weight);
        setMovies(prevMovies => {
          if (JSON.stringify(prevMovies) !== JSON.stringify(movieList)) {
            return movieList;
          }
          return prevMovies;
        });
      } else {
        setMovies([]);
      }
      setIsListRefreshing(false);
      setIsInitialLoading(false);
    }, (error) => {
      console.error('Error fetching movies:', error);
      setIsListRefreshing(false);
      setIsInitialLoading(false);
      setNotification({ message: 'Error fetching movies', type: 'error' });
    });
  }, []);

  const fetchPublicList = useCallback(() => {
    console.log('Fetching public list');
    setLoadingStage('Fetching public list');
    const publicListsRef = ref(database, 'publicLists');
    return get(publicListsRef).then((snapshot) => {
      if (snapshot.exists()) {
        const publicLists = snapshot.val();
        const firstListId = Object.keys(publicLists)[0];
        setCurrentList(`public_${firstListId}`);
        console.log('Public list found, fetching movies');
        return fetchMoviesFromFirebase(`publicLists/${firstListId}/movies`);
      } else {
        console.log('No public lists available');
        setMovies([]);
        setNotification({ message: 'No public lists available.', type: 'info' });
      }
    }).catch((error) => {
      console.error('Error fetching public list:', error);
      setNotification({ message: 'Failed to fetch public list. Please try again later.', type: 'error' });
    });
  }, [fetchMoviesFromFirebase, setCurrentList, setMovies, setNotification]);


  const clearUserData = useCallback(() => {
    setUserLists([]);
    setCurrentList('master');
    setLocalChanges({});
    setUnsavedChanges(false);
  }, []);

  useEffect(() => {
    const testRef = ref(database, 'test');
    onValue(testRef, (snapshot) => {
      console.log('Firebase connection test:', snapshot.val());
    }, (error) => {
      console.error('Firebase connection error:', error);
    });
  }, []);

  const verifyMovie = async (title) => {
    setIsLoading(true);
    setError('');
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 1000);
  
      const apiUrl = `https://www.omdbapi.com/?apikey=${OMDB_API_KEY}&t=${encodeURIComponent(title)}&type=movie`;
      const response = await fetch(apiUrl, { signal: controller.signal });
      clearTimeout(timeoutId);
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (data.Response === "True") {
        return {
          verified: true,
          movieData: {
            Title: data.Title,
            Year: data.Year,
            Poster: data.Poster,
            imdbID: data.imdbID,
            Country: data.Country
          }
        };
      } else {
        throw new Error(data.Error || "Movie not found");
      }
    } catch (error) {
      console.error('Error verifying movie:', error);
      return { verified: false, error: error.message };
    } finally {
      setIsLoading(false);
    }
  };
  
  const toggleMyLists = useCallback(() => {
    setIsMyListsOpen(prevState => !prevState);
  }, []);
  
  const toggleManualEntry = (isManual) => {
    setIsManualEntry(isManual);
    if (!isManual && searchInputRef.current) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 0);
    }
  };

  const togglePublicLists = useCallback(() => {
    setIsPublicListsOpen(prevState => !prevState);
  }, []);

  const checkAndSetAdminStatus = async (userId) => {
    const userRef = ref(database, `users/${userId}`);
    const userSnapshot = await get(userRef);
    const userData = userSnapshot.val();
  
    if (!userData || userData.isAdmin === undefined) {
      console.log('Setting admin status for user:', userId);
      await update(userRef, {
        isAdmin: false // Set to true for admin users
      });
      return false;
    }
  
    return userData.isAdmin === true;
  };
  
  const fetchSuggestions = useCallback(async (term) => {
    console.log('Fetching suggestions for:', term);
    setPendingSearch(true);
    setError('');
    
    try {
      const apiUrl = `https://www.omdbapi.com/?apikey=${OMDB_API_KEY}&s=${encodeURIComponent(term)}&type=movie`;
      console.log('Fetching from URL:', apiUrl);
  
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      
      if (data.Response === "True") {
        setSuggestions(data.Search);
        setHasSearched(true);
        setLastSuccessfulTerm(term);
      } else {
        // Clear suggestions if:
        // 1. Enter was pressed (indicating explicit search), or
        // 2. No partial match with previous successful search
        const isPartialMatch = term.toLowerCase().includes(lastSuccessfulTerm.toLowerCase()) ||
                              lastSuccessfulTerm.toLowerCase().includes(term.toLowerCase());
        
        if (!isPartialMatch || term === searchTerm) { // searchTerm comparison checks if this was the final term
          setSuggestions([]);
          setHasSearched(true); // Explicitly mark as searched when clearing results
        }
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setError(`An error occurred: ${error.message}`);
      setSuggestions([]);
      setHasSearched(true);
    } finally {
      setPendingSearch(false);
      setIsLoading(false);
    }
  }, [OMDB_API_KEY, lastSuccessfulTerm, searchTerm]);
  
  // Update the existing useEffect or add this if it doesn't exist
  useEffect(() => {
    console.log('Search term changed:', searchTerm);
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        console.log('Calling fetchSuggestions with:', searchTerm);
        fetchSuggestions(searchTerm);
      } else {
        console.log('Clearing suggestions');
        setSuggestions([]);
      }
    }, 300);
  
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, fetchSuggestions]);

  useEffect(() => {
    console.log('Fetching lists');
    
    // Always fetch public lists
    const publicListsRef = ref(database, 'publicLists');
    const unsubscribePublicLists = onValue(publicListsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const lists = Object.entries(data).map(([id, list]) => ({
          id,
          name: list.name,
          authorHandle: list.authorHandle || 'Anonymous',
        }));
        setPublicLists(lists);
        console.log('Public lists fetched:', lists);
      } else {
        console.log('No public lists found');
        setPublicLists([]);
      }
    }, (error) => {
      console.error('Error fetching public lists:', error);
    });
  
    // Always fetch master list
    fetchMasterMovieList();
  
    // Fetch user lists only if user is logged in
    let unsubscribeUserLists = null;
    if (user) {
      const userListsRef = ref(database, `users/${user.uid}/lists`);
      unsubscribeUserLists = onValue(userListsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const lists = Object.entries(data).map(([id, list]) => ({
            id,
            name: list.name,
          }));
          setUserLists(lists);
          console.log('User lists fetched:', lists);
        } else {
          console.log('No user lists found');
          setUserLists([]);
        }
      }, (error) => {
        console.error('Error fetching user lists:', error);
      });
    } else {
      setUserLists([]);
    }
  
    // Cleanup function
    return () => {
      unsubscribePublicLists();
      if (unsubscribeUserLists) {
        unsubscribeUserLists();
      }
    };
  }, [user, database, fetchMasterMovieList]);

  useEffect(() => {
    console.log('Firebase effect running');
    setLoadingStage('Initializing authentication');
    let unsubscribeFromMovies = null;
  
    const unsubscribeAuth = auth.onAuthStateChanged(async (authUser) => {
      try {
        console.log('Auth state changed:', authUser ? 'User authenticated' : 'No user');
        
        // Clean up any existing subscription
        if (unsubscribeFromMovies && typeof unsubscribeFromMovies === 'function') {
          unsubscribeFromMovies();
          unsubscribeFromMovies = null;
        }
        
        if (authUser) {
          setLoadingStage('User authenticated, fetching user data');
          setUser(authUser);
          setIsLoggedIn(true);
          setIsGuest(false);
          
          // Fetch user data
          const userRef = ref(database, `users/${authUser.uid}`);
          const userSnapshot = await get(userRef);
          let userData = userSnapshot.val() || {};
          
          setLoadingStage('Checking admin status');
          const shouldBeAdmin = authUser.email === ADMIN_EMAIL;
          
          if (shouldBeAdmin && !userData.isAdmin) {
            await setAdminStatus(authUser.uid, true);
            userData.isAdmin = true;
          }
          
          setIsAdmin(userData.isAdmin === true);
          console.log('Is admin:', userData.isAdmin === true);
          setUserHandle(userData.handle || authUser.email);
  
          setLoadingStage('Fetching movies');
          if (userData.isAdmin) {
            console.log('Admin user, fetching master list');
            unsubscribeFromMovies = fetchMasterMovieList();
          } else {
            console.log('Regular user, fetching user movies');
            unsubscribeFromMovies = fetchUserMovies(authUser.uid);
          }
        } else {
          setLoadingStage('No user, fetching public list');
          setUser(null);
          setIsLoggedIn(false);
          setIsGuest(true);
          setIsAdmin(false);
          setUserHandle('');
          clearUserData();
          
          console.log('Fetching public list for guest');
          unsubscribeFromMovies = fetchPublicList();
        }
      } catch (error) {
        console.error('Error during authentication:', error);
        setNotification({ message: 'An error occurred during authentication. Please try again.', type: 'error' });
        setLoadingStage('Error during authentication');
        
        if (unsubscribeFromMovies && typeof unsubscribeFromMovies === 'function') {
          unsubscribeFromMovies();
        }
        unsubscribeFromMovies = fetchPublicList();
      } finally {
        setIsLoading(false);
        setIsFirebaseLoaded(true);
        setIsAuthInitialized(true);
        setIsInitialLoading(false);
      }
    });
  
    return () => {
      console.log('Cleaning up Firebase effect');
      if (unsubscribeFromMovies && typeof unsubscribeFromMovies === 'function') {
        unsubscribeFromMovies();
      }
      if (unsubscribeAuth && typeof unsubscribeAuth === 'function') {
        unsubscribeAuth();
      }
    };
  }, [fetchMasterMovieList, fetchUserMovies, fetchPublicList, clearUserData, ADMIN_EMAIL]);

  const addMovieToFirebase = async (movieData) => {
    if (isAdmin) {
      try {
        const moviesRef = ref(database, 'movies');
        const newMovieRef = push(moviesRef);
        await set(newMovieRef, movieData);
        console.log('Movie successfully added to Firebase');
      } catch (error) {
        console.error('Error adding movie to Firebase:', error);
        setError('Failed to add movie to database. Please try again.');
      }
    }
  };


  const updateMovieInFirebase = async (id, updatedData) => {
    if (!user) return;
    const movieRef = ref(database, isLoggedIn ? `users/${user.uid}/movies/${id}` : `movies/${id}`);
    
    // Only update the weight and preserve other details
    const { weight } = updatedData;
    await update(movieRef, { weight });
  };


  const deleteMovieFromFirebase = async (id) => {
    if (!user) return;
    const movieRef = ref(database, isLoggedIn ? `users/${user.uid}/movies/${id}` : `movies/${id}`);
    await remove(movieRef);
  };

  const updateMasterListWeight = async (movieId, weightChange) => {
    if (!isAdmin) {
      console.log('Non-admin attempted to update master list weight');
      return;
    }
    const masterMovieRef = ref(database, `movies/${movieId}`);
    const snapshot = await get(masterMovieRef);
    if (snapshot.exists()) {
      const currentWeight = snapshot.val().weight || 0;
      await update(masterMovieRef, { weight: currentWeight + weightChange });
    } else {
      // If the movie doesn't exist in the master list, add it
      const movieData = movies.find(m => m.id === movieId);
      if (movieData) {
        await set(masterMovieRef, { ...movieData, weight: weightChange });
      }
    }
  };

  const addMovie = async (selectedMovie) => {
  console.log('Adding movie:', selectedMovie);
  setIsLoading(true);
  
  try {
    let movieData;
    if (manualEntryMode) {
      console.log(' Manual Entry mode:', selectedMovie);

      movieData = {
        title: manualMovieData.title,
        year: manualMovieData.year,
        poster: null,
        imdbId: `manual_${Date.now()}`,
        country: manualMovieData.country,
        language: manualMovieData.language || getLanguageFromCountry(manualMovieData.country),
      };
    } else {
      console.log(' Not Manual Entry mode:', selectedMovie);

      const verificationResult = await verifyMovie(selectedMovie.Title);
      if (verificationResult.verified) {
        movieData = {
          title: verificationResult.movieData.Title,
          year: verificationResult.movieData.Year,
          poster: verificationResult.movieData.Poster,
          imdbId: verificationResult.movieData.imdbID,
          country: verificationResult.movieData.Country,
          language: verificationResult.movieData.Language || getLanguageFromCountry(verificationResult.movieData.Country),
        };
      } else {
        throw new Error("Couldn't verify movie.");
      }
    }
    
    const newMovie = {
      ...movieData,
      id: `movie_${Date.now()}`,
      weight: 1,
    };
  
    if (movieExistsInList(newMovie, movies)) {
      setNotification({ message: 'This movie is already in the list.', type: 'warning' });
      setIsLoading(false);
      return;
    }
  
    const updatedMovies = [...movies, newMovie];
  
    if (isAdmin && currentList === 'master') {
      await updateMasterList(updatedMovies);
      setMovies(updatedMovies);
      setMasterList(updatedMovies);
    } else {
      updateLocalChanges(currentList, updatedMovies);
    }

    console.log('Movie added, updated movies:', updatedMovies);

    setSearchTerm('');
    setSuggestions([]);
    setManualEntryMode(false);
    setManualMovieData({ title: '', year: '', country: '' });
  } catch (error) {
    console.error('Error adding movie:', error);
    setNotification({ message: error.message, type: 'error' });
  } finally {
    setIsLoading(false);
  }
};

const sortedMovies = useMemo(() => {
  return [...movies].sort((a, b) => b.weight - a.weight);
}, [movies]);

const updateMasterList = async (moviesToAdd) => {
  const masterListRef = ref(database, 'movies');
  const masterSnapshot = await get(masterListRef);
  const existingMasterList = masterSnapshot.val() || {};

  const existingMovies = Object.values(existingMasterList);
  const updatedMovies = updateListWithWeights(existingMovies, moviesToAdd);

  const updates = {};
  updatedMovies.forEach(movie => {
    updates[movie.id] = movie;
  });

  await update(masterListRef, updates);
  console.log("Master list updated");
};

const updatePublicList = async (publicListId, moviesToAdd) => {
  const publicListRef = ref(database, `publicLists/${publicListId}/movies`);
  const publicListSnapshot = await get(publicListRef);
  const existingPublicList = publicListSnapshot.val() || {};

  const existingMovies = Object.values(existingPublicList);
  const updatedMovies = updateListWithWeights(existingMovies, moviesToAdd);

  const updates = {};
  updatedMovies.forEach(movie => {
    updates[movie.id] = movie;
  });

  await update(publicListRef, updates);
  console.log("Public list updated");
};

const onDragEnd = useCallback((result) => {
  if (!result.destination) {
    return;
  }

  const newMovies = Array.from(movies);
  const [reorderedItem] = newMovies.splice(result.source.index, 1);
  newMovies.splice(result.destination.index, 0, reorderedItem);

  const updatedMovies = recalculateWeights(newMovies);

  setMovies(updatedMovies);
  updateLocalChanges(currentList, updatedMovies);
  setHasUnsavedChanges(true);
}, [movies, currentList, updateLocalChanges, setHasUnsavedChanges, recalculateWeights]);

  const recalculateMasterList = useCallback(async () => {
    try {
      const publicListsRef = ref(database, 'publicLists');
      const publicListsSnapshot = await get(publicListsRef);
  
      const masterList = {};
  
      // Process public lists
      publicListsSnapshot.forEach(listSnapshot => {
        const listData = listSnapshot.val();
        if (listData && listData.movies) {
          Object.values(listData.movies).forEach(movie => {
            if (!masterList[movie.id]) {
              masterList[movie.id] = { ...movie, weight: 0 };
            }
            masterList[movie.id].weight += 1;
          });
        }
      });
  
      // Process current user's lists if logged in
      if (user) {
        const userListsRef = ref(database, `users/${user.uid}/lists`);
        const userListsSnapshot = await get(userListsRef);
        
        userListsSnapshot.forEach(listSnapshot => {
          const listData = listSnapshot.val();
          if (listData && listData.movies) {
            Object.values(listData.movies).forEach(movie => {
              if (!masterList[movie.id]) {
                masterList[movie.id] = { ...movie, weight: 0 };
              }
              masterList[movie.id].weight += 1;
            });
          }
        });
      }
  
      setMasterList(Object.values(masterList).sort((a, b) => b.weight - a.weight));
    } catch (error) {
      console.error('Error recalculating master list:', error);
      setNotification('Failed to update master list. Please try again later.', 'error');
    }
  }, [database, user]);
  
  // Call this function when the component mounts and when the user changes
  useEffect(() => {
    recalculateMasterList();
  }, [recalculateMasterList, user]);

  const updatePrivateList = async (listId) => {
    try {
      const fullListId = listId.startsWith('private_') ? listId.replace('private_', '') : listId;
      const userListRef = ref(database, `users/${user.uid}/lists/${fullListId}`);
      await update(userListRef, { 
        movies: movies.reduce((acc, movie) => {
          acc[movie.id] = { ...movie, weight: movie.weight };
          return acc;
        }, {})
      });
  
      setNotification({ message: "Private list updated successfully!", type: "success" });
  
      // Update the public list if it exists
      const userListSnapshot = await get(userListRef);
      const userListData = userListSnapshot.val();
      if (userListData && userListData.publicListId) {
        await updatePublicList(userListData.publicListId);
      }
  
      // Update the master list
      await updateMasterList(movies);
  
    } catch (error) {
      console.error("Error updating private list:", error);
      setNotification({ message: "Failed to update private list. Please try again.", type: "error" });
    }
  };

  const fetchUserLists = useCallback(async (userId) => {
    const userListsRef = ref(database, `users/${userId}/lists`);
    const snapshot = await get(userListsRef);
    if (snapshot.exists()) {
      const listsData = snapshot.val();
      const lists = Object.entries(listsData).map(([id, list]) => ({
        id,
        name: list.name,
        publicListId: list.publicListId
      }));
      setUserLists(lists);
    } else {
      setUserLists([]);
    }
  }, []);
  
  const fetchPublicLists = useCallback(async () => {
    try {
      const publicListsRef = ref(database, 'publicLists');
      const snapshot = await get(publicListsRef);
      if (snapshot.exists()) {
        const listsData = snapshot.val();
        const lists = Object.entries(listsData).map(([id, list]) => ({
          id,
          name: list.name,
          authorHandle: list.authorHandle || 'Anonymous',
          owner: list.author // Keep this if you need it elsewhere
        }));
        setPublicLists(lists);
      } else {
        setPublicLists([]);
      }
    } catch (error) {
      console.error('Error fetching public lists:', error);
      setNotification({ message: 'Failed to fetch public lists. Please try again later.', type: 'error' });
    }
  }, [database, setNotification]);
  

  const fetchPrivateList = async (listId) => {
    const privateListRef = ref(database, `users/${auth.currentUser.uid}/lists/${listId}/movies`);
    const snapshot = await get(privateListRef);
    return Object.values(snapshot.val() || {});
  };

  const handleAuth = useCallback(async () => {
    if (auth.currentUser) {
      try {
        await signOut(auth);
        // The rest of the state updates will be handled in the onAuthStateChanged listener
      } catch (error) {
        console.error('Error logging out:', error);
        alert('Failed to log out. Please try again.');
      }
    } else {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: 'select_account' });
      try {
        await signInWithPopup(auth, provider);
        // The rest of the state updates will be handled in the onAuthStateChanged listener
      } catch (error) {
        console.error('Error logging in:', error);
        alert('Failed to log in. Please try again.');
      }
    }
  }, [auth]); // Only dependency is auth, which should be stable
  
  // Separate effect to handle auth state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = ref(database, `users/${user.uid}`);
        const userSnapshot = await get(userRef);
        const userData = userSnapshot.val() || {};
        
        if (!userData.handle) {
          const nickname = prompt("Please enter a nickname (this will be visible to other users):", user.displayName || "");
          if (nickname) {
            await update(userRef, { handle: nickname });
            setUserHandle(nickname);
          }
        } else {
          setUserHandle(userData.handle);
        }
        
        setUser(user);
        setIsLoggedIn(true);
        setIsAdmin(userData.isAdmin || false);
        fetchUserLists(user.uid);
        setNotification({ message: 'Logged in successfully', type: 'success' });
      } else {
        setUser(null);
        setIsLoggedIn(false);
        setUserHandle('');
        setIsAdmin(false);
        setUserLists([]);
        setCurrentList('master');
        fetchPublicLists();
        fetchMasterMovieList();
        setNotification({ message: 'Logged out successfully', type: 'success' });
      }
    });
  
    return () => unsubscribe();
  }, [auth, database, fetchUserLists, fetchPublicLists, fetchMasterMovieList]);

  const saveUserData = useCallback(async (newListName, makePublic = false, moviesToSave, originalPublicListId = null) => {
    if (!isLoggedIn) {
      throw new Error('You need to be logged in to save a list.');
    }
  
    try {
      // Remove duplicates
      const uniqueMovies = moviesToSave.reduce((acc, movie) => {
        if (!acc.some(m => m.title.toLowerCase() === movie.title.toLowerCase() && m.year === movie.year)) {
          acc.push(movie);
        }
        return acc;
      }, []);
  
      let publicListId = null;
      let userListId = null;
  
      if (makePublic) {
        const publicListRef = push(ref(database, 'publicLists'));
        publicListId = publicListRef.key;
        await set(publicListRef, {
          name: newListName,
          author: auth.currentUser.uid,
          authorHandle: userHandle,
          movies: uniqueMovies.reduce((acc, movie) => {
            acc[movie.id] = { ...movie, weight: movie.weight };
            return acc;
          }, {}),
        });
      }
  
      const userListRef = push(ref(database, `users/${auth.currentUser.uid}/lists`));
      userListId = userListRef.key;
      await set(userListRef, { 
        name: newListName,
        movies: uniqueMovies.reduce((acc, movie) => {
          acc[movie.id] = { ...movie, weight: movie.weight };
          return acc;
        }, {}),
        publicListId: publicListId,
        originalPublicListId: originalPublicListId
      });
  
      setUserLists(prevLists => [
        ...prevLists,
        { 
          id: userListId, 
          name: newListName,
          publicListId: publicListId,
          originalPublicListId: originalPublicListId
        }
      ]);
  
      setLocalChanges(prevChanges => {
        const newChanges = { ...prevChanges };
        delete newChanges[currentList];
        return newChanges;
      });
      
      setHasUnsavedChanges(false);
      setCurrentList(userListId);
  
      setNotification({ 
        message: `List "${newListName}" saved successfully${makePublic ? ' and made public' : ''}!`, 
        type: 'success' 
      });
  
    } catch (error) {
      console.error('Error saving user data:', error);
      setNotification({ message: `Failed to save list: ${error.message}`, type: 'error' });
      throw error;
    }
  }, [isLoggedIn, database, auth, userHandle, currentList, setUserLists, setLocalChanges, setHasUnsavedChanges, setCurrentList, setNotification]);
  
  const updatePublicListAsGuest = async (publicListId, moviesToSave) => {
    try {
      const publicListRef = ref(database, `publicLists/${publicListId}/movies`);
      const updates = moviesToSave.reduce((acc, movie) => {
        acc[movie.id] = movie;
        return acc;
      }, {});
      await update(publicListRef, updates);
      console.log('Public list updated as guest');
    } catch (error) {
      console.error('Error in updatePublicListAsGuest:', error);
      throw error;
    }
  };
  
  const updateMasterListAsGuest = async (moviesToSave) => {
    try {
      const masterListRef = ref(database, 'movies');
      const masterSnapshot = await get(masterListRef);
      const existingMasterList = masterSnapshot.val() || {};
  
      const updates = {};
      moviesToSave.forEach((movie, index) => {
        if (existingMasterList[movie.id]) {
          // Update existing movie
          updates[movie.id] = {
            ...existingMasterList[movie.id],
            ...movie,
            weight: moviesToSave.length - index // Update weight based on new position
          };
        } else {
          // Add new movie
          updates[movie.id] = {
            ...movie,
            weight: moviesToSave.length - index
          };
        }
      });
  
      await update(masterListRef, updates);
      console.log('Master list updated as guest');
      
      // Update local state
      setMasterList(Object.values(updates).sort((a, b) => b.weight - a.weight));
      setMovies(Object.values(updates).sort((a, b) => b.weight - a.weight));
    } catch (error) {
      console.error('Error in updateMasterListAsGuest:', error);
      throw error;
    }
  };

  const saveCurrentList = useCallback(async () => {
  try {
    const moviesToSave = localChanges[currentList] || movies;
    console.log('Saving current list:', currentList, 'Movies to save:', moviesToSave);
    
    if (currentList.startsWith('public_') || currentList === 'master') {
      const listId = currentList.startsWith('public_') ? currentList.replace('public_', '') : 'master';
      
      try {
        if (listId === 'master') {
          await updateMasterListAsGuest(moviesToSave);
          console.log('Master list updated successfully');
        } else {
          await updatePublicListAsGuest(listId, moviesToSave);
          console.log('Public list updated successfully');
        }

        // Always update master list when updating a public list
        if (listId !== 'master') {
          await updateMasterListAsGuest(moviesToSave);
          console.log('Master list updated successfully');
        }
      } catch (error) {
        console.error('Error updating list:', error);
        throw error;
      }

      setNotification({ message: "List updated successfully!", type: "success" });
    } else if (isLoggedIn && (currentList.startsWith('private_') || userLists.some(list => list.id === currentList))) {
      await updatePrivateList(currentList.replace('private_', ''), moviesToSave);
      console.log('Private list updated');
      setNotification({ message: "Private list updated successfully!", type: "success" });
    } else {
      console.log('Unable to save changes');
      setNotification({ message: "Unable to save changes. Please check your permissions.", type: "warning" });
    }

    // Clear local changes
    setLocalChanges(prevChanges => {
      const newChanges = { ...prevChanges };
      delete newChanges[currentList];
      return newChanges;
    });
    setHasUnsavedChanges(false);

  } catch (error) {
    console.error('Error saving list:', error);
    console.error('Error details - Current list:', currentList, 'Is logged in:', isLoggedIn, 'Is admin:', isAdmin);
    setNotification({ message: `Failed to save list: ${error.message}`, type: 'error' });
  }
}, [currentList, localChanges, movies, isLoggedIn, userLists, updatePublicListAsGuest, updateMasterListAsGuest, 
  updatePrivateList, setLocalChanges, setHasUnsavedChanges, setNotification]);
  
  const updateListWithWeights = (existingMovies, newMovies) => {
      const updatedMovies = [...existingMovies];
      
      newMovies.forEach(newMovie => {
        const existingIndex = updatedMovies.findIndex(m => m.id === newMovie.id);
        
        if (existingIndex === -1) {
          // If the movie doesn't exist, add it with its current weight
          updatedMovies.push(newMovie);
        } else {
          const existingMovie = updatedMovies[existingIndex];
          if (existingMovie.weight === newMovie.weight) {
            // If weights are the same, increase weight of this movie and all above it
            for (let i = existingIndex; i >= 0; i--) {
              updatedMovies[i].weight += 1;
            }
          }
          // Update other properties of the existing movie
          updatedMovies[existingIndex] = {
            ...existingMovie,
            ...newMovie,
            weight: Math.max(existingMovie.weight, newMovie.weight)
          };
        }
      });
    
      // Sort the movies by weight in descending order
      return updatedMovies.sort((a, b) => b.weight - a.weight);
    };
    
  const recalculateMasterListWeights = async () => {
    try {
      const allListsRef = ref(database, 'users');
      const publicListsRef = ref(database, 'publicLists');
      const masterListRef = ref(database, 'movies');
  
      const [allListsSnapshot, publicListsSnapshot, masterSnapshot] = await Promise.all([
        get(allListsRef),
        get(publicListsRef),
        get(masterListRef)
      ]);
  
      const weightCounts = {};
  
      // Count weights from all user lists
      allListsSnapshot.forEach(userSnapshot => {
        const userData = userSnapshot.val();
        if (userData && userData.lists) {
          Object.values(userData.lists).forEach(list => {
            if (list.movies) {
              Object.values(list.movies).forEach(movie => {
                weightCounts[movie.id] = (weightCounts[movie.id] || 0) + 1;
              });
            }
          });
        }
      });
  
      // Count weights from public lists
      publicListsSnapshot.forEach(listSnapshot => {
        const listData = listSnapshot.val();
        if (listData && listData.movies) {
          Object.values(listData.movies).forEach(movie => {
            weightCounts[movie.id] = (weightCounts[movie.id] || 0) + 1;
          });
        }
      });
  
      // Update master list with new weights
      const updates = {};
      masterSnapshot.forEach(movieSnapshot => {
        const movieData = movieSnapshot.val();
        updates[movieSnapshot.key] = {
          ...movieData,
          weight: weightCounts[movieSnapshot.key] || 0
        };
      });
  
      await update(masterListRef, updates);
      setMasterList(Object.values(updates));
      setNotification({ message: 'Master list weights recalculated', type: 'success' });
    } catch (error) {
      console.error('Error recalculating master list weights:', error);
      setNotification({ message: 'Failed to recalculate master list weights', type: 'error' });
    }
  };

  const handleDeleteMovie = useCallback(async (id) => {
    if (window.confirm('Are you sure you want to delete this movie?')) {
      try {
        if (isAdmin && currentList === 'master') {
          // Admin deleting from master list
          const movieRef = ref(database, `movies/${id}`);
          await remove(movieRef);
          
          // Update local state
          setMovies(prev => recalculateWeights(prev.filter(movie => movie.id !== id)));
          setMasterList(prev => recalculateWeights(prev.filter(movie => movie.id !== id)));
          
          // Update weights in all public lists
          const publicListsRef = ref(database, 'publicLists');
          const publicListsSnapshot = await get(publicListsRef);
          if (publicListsSnapshot.exists()) {
            const publicLists = publicListsSnapshot.val();
            for (const listId in publicLists) {
              if (publicLists[listId].movies && publicLists[listId].movies[id]) {
                const updatedPublicMovies = Object.values(publicLists[listId].movies)
                  .filter(movie => movie.id !== id);
                const recalculatedPublicMovies = recalculateWeights(updatedPublicMovies);
                await set(ref(database, `publicLists/${listId}/movies`), 
                  recalculatedPublicMovies.reduce((acc, movie) => ({...acc, [movie.id]: movie}), {}));
              }
            }
          }
        } else if (isLoggedIn) {
          // Logged-in user deleting from their list
          const updatedMovies = recalculateWeights(movies.filter(movie => movie.id !== id));
          updateLocalChanges(currentList, updatedMovies);
          setMovies(updatedMovies);
          setHasUnsavedChanges(true);
  
          if (currentList.startsWith('private_') || userLists.some(list => list.id === currentList)) {
            const listId = currentList.replace('private_', '');
            const userListRef = ref(database, `users/${user.uid}/lists/${listId}/movies`);
            await set(userListRef, updatedMovies.reduce((acc, movie) => ({...acc, [movie.id]: movie}), {}));
          }
        } else {
          // Non-logged-in user deleting from a public list
          const updatedMovies = recalculateWeights(movies.filter(movie => movie.id !== id));
          updateLocalChanges(currentList, updatedMovies);
          setMovies(updatedMovies);
          setHasUnsavedChanges(true);
        }
  
        setNotification({ message: 'Movie removed from the list', type: 'success' });
      } catch (error) {
        console.error('Error deleting movie:', error);
        setNotification({ message: 'Failed to delete movie. Please try again.', type: 'error' });
      }
    }
  }, [currentList, isAdmin, updateLocalChanges, setMovies, setMasterList, setNotification]);
  
  const handleDeleteList = useCallback(async (listId, listName) => {
    if (!user) return;
    
    if (window.confirm(`Are you sure you want to delete the list "${listName}"?`)) {
      try {
        // Get the list data
        const listRef = ref(database, `users/${user.uid}/lists/${listId}`);
        const listSnapshot = await get(listRef);
        const listData = listSnapshot.val();
  
        if (listData) {
          // Delete the user's list
          await remove(listRef);
  
          // Update local user lists state
          setUserLists(prevLists => prevLists.filter(list => list.id !== listId));
  
          // If the deleted list was the current list, switch to master list
          if (currentList === listId) {
            setCurrentList('master');
            fetchMasterMovieList();
          }
  
          setNotification({ message: `List "${listName}" deleted successfully!`, type: 'success' });
        } else {
          setNotification({ message: 'List not found.', type: 'error' });
        }
      } catch (error) {
        console.error('Error deleting list:', error);
        setNotification({ message: 'Failed to delete list. Please try again.', type: 'error' });
      }
    }
  }, [user, currentList, fetchMasterMovieList]);


  const handleUnsavedChanges = useCallback(async (newListId) => {
    if (hasUnsavedChanges) {
      const confirmSave = window.confirm("You have unsaved changes. Do you want to save them before switching lists?");
      if (confirmSave) {
        await saveCurrentList();
      } else {
        // User chose to discard changes
        setHasUnsavedChanges(false);
        clearLocalChanges();
        // Sync with database
        if (newListId === 'master') {
          await fetchMasterMovieList();
        } else if (newListId.startsWith('public_')) {
          await fetchMoviesFromFirebase(`publicLists/${newListId.replace('public_', '')}/movies`);
        } else if (isLoggedIn) {
          await fetchMoviesFromFirebase(`users/${user.uid}/lists/${newListId}/movies`);
        }
      }
    }
  }, [hasUnsavedChanges,
     saveCurrentList, 
    clearLocalChanges, 
    fetchMasterMovieList, 
    fetchMoviesFromFirebase, 
    isLoggedIn]);

  const handleShowMasterList = useCallback(async () => {
    await handleUnsavedChanges('master');
    setCurrentList('master');
    fetchMasterMovieList();
    setIsSidebarOpen(false);
  }, [handleUnsavedChanges, fetchMasterMovieList]);
  
  const handleShowUserList = useCallback(async (listId) => {
    await handleUnsavedChanges(listId);
    setCurrentList(listId);
    fetchMoviesFromFirebase(`users/${user.uid}/lists/${listId}/movies`);
    setIsSidebarOpen(false);
  }, [handleUnsavedChanges, fetchMoviesFromFirebase, user]);
  
  const handleShowPublicList = useCallback(async (listId) => {
    await handleUnsavedChanges(`public_${listId}`);
    setCurrentList(`public_${listId}`);
    fetchMoviesFromFirebase(`publicLists/${listId}/movies`);
    setIsSidebarOpen(false);
  }, [handleUnsavedChanges, fetchMoviesFromFirebase]);

  const handleNewList = async () => {
    if (!isLoggedIn) {
      const wantsToLogin = window.confirm("You need to log in to create a list. Would you like to log in now?");
      if (wantsToLogin) {
        await handleAuth();
        if (!isLoggedIn) return;
      } else {
        return;
      }
    }

    const listName = prompt('Enter a name for your new list:');
    if (!listName) return;

    try {
      setNotification({ message: 'Creating new list...', type: 'info' });

      // Create a new empty list in the user's lists
      const userListRef = push(ref(database, `users/${user.uid}/lists`));
      const listId = userListRef.key;
      
      const isPublic = window.confirm('Do you want to make this list public?');
      let publicListId = null;

      if (isPublic) {
        // Create public list if requested
        const publicListRef = push(ref(database, 'publicLists'));
        publicListId = publicListRef.key;
        
        await set(publicListRef, {
          name: listName,
          author: user.uid,
          authorHandle: userHandle,
          movies: {} // Empty movies object
        });
      }

      // Save the user list
      await set(userListRef, {
        name: listName,
        movies: {}, // Empty movies object
        publicListId: publicListId
      });

      // Update local state
      setUserLists(prevLists => [
        ...prevLists,
        {
          id: listId,
          name: listName,
          publicListId: publicListId
        }
      ]);

      // Switch to the new list
      setCurrentList(listId);
      setMovies([]);
      setNotification({ 
        message: `New list "${listName}" created successfully!`, 
        type: 'success' 
      });

    } catch (error) {
      console.error('Error creating new list:', error);
      setNotification({ 
        message: 'Failed to create new list. Please try again.', 
        type: 'error' 
      });
    }
  };

  const handleUpdatePoints = async () => {
    if (!isAdmin) return;

    try {
      setNotification({ message: 'Updating points...', type: 'info' });
      
      // Get all public lists
      const publicListsRef = ref(database, 'publicLists');
      const publicListsSnapshot = await get(publicListsRef);
      const publicLists = publicListsSnapshot.val() || {};

      // Get all user lists
      const usersRef = ref(database, 'users');
      const usersSnapshot = await get(usersRef);
      const users = usersSnapshot.val() || {};

      // Create a map to store movie points
      const moviePoints = {};

      // Count points from public lists
      Object.values(publicLists).forEach(list => {
        if (list.movies) {
          Object.values(list.movies).forEach(movie => {
            moviePoints[movie.id] = (moviePoints[movie.id] || 0) + movie.weight;
          });
        }
      });

      // Count points from user lists
      Object.values(users).forEach(user => {
        if (user.lists) {
          Object.values(user.lists).forEach(list => {
            if (list.movies) {
              Object.values(list.movies).forEach(movie => {
                moviePoints[movie.id] = (moviePoints[movie.id] || 0) + movie.weight;
              });
            }
          });
        }
      });

      // Update master list with new points
      const masterListRef = ref(database, 'movies');
      const masterListSnapshot = await get(masterListRef);
      const masterList = masterListSnapshot.val() || {};

      // Update weights in master list
      const updates = {};
      Object.entries(masterList).forEach(([movieId, movieData]) => {
        updates[movieId] = {
          ...movieData,
          weight: moviePoints[movieId] || 0
        };
      });

      // Apply updates to master list
      await update(masterListRef, updates);

      setNotification({ message: 'Points updated successfully!', type: 'success' });
      
      // Refresh the master list display
      if (currentList === 'master') {
        fetchMasterMovieList();
      }

    } catch (error) {
      console.error('Error updating points:', error);
      setNotification({ message: 'Failed to update points', type: 'error' });
    }
  };

  const handleEditClick = useCallback((movie) => {
    if (currentList !== 'master' && isGuest) {
      setError('You need to be logged in to edit movies in this list.');
      return;
    }
    setEditingMovie(movie);
    setManualEntryMode(true);
    setManualMovieData({
      title: movie.title,
      year: movie.year,
      country: movie.country || '',
    });
  }, [currentList, isGuest, setError, setEditingMovie, setManualEntryMode, setManualMovieData]);

  const handleSearch = useCallback(async (term) => {
    setIsLoading(true);
    try {
      const results = await fetchSuggestions(term);
      setSuggestions(results || []);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setNotification({ message: 'Failed to fetch movie suggestions', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [fetchSuggestions, setNotification]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && searchTerm) {
      debouncedHandleSearch.cancel(); // Cancel any pending debounced search
      setHasSearched(false); // Reset search state
      handleSearch(searchTerm); // Immediately perform the search
    }
  };

  const handleSaveData = async () => {
    if (!isLoggedIn) {
      const wantsToLogin = window.confirm("You need to log in to save your list. Would you like to log in now?");
      if (wantsToLogin) {
        await handleAuth();
        if (!isLoggedIn) return; // Exit if login failed
      } else {
        return; // Exit if user doesn't want to login
      }
    }
    
    const isPublic = window.confirm('Do you want to make this list public?');
    const newListName = prompt('Enter a name for this list:', 'My New List');
    if (newListName) {
      await saveUserData(newListName, isPublic);
    }
  };
  
  const handleDeletePublicList = async (listId, listName) => {
    if (!isAdmin) {
      setNotification({ message: 'Only admins can delete public lists.', type: 'error' });
      return;
    }
  
    if (window.confirm(`Are you sure you want to delete the public list "${listName}"?`)) {
      try {
        const publicListRef = ref(database, `publicLists/${listId}`);
        await remove(publicListRef);
        
        // Update local state
        setPublicLists(prevLists => prevLists.filter(list => list.id !== listId));
        
        // Remove the list from userLists if it exists there
        setUserLists(prevLists => prevLists.filter(list => list.publicListId !== listId));
                
        // If the current list is the one being deleted, switch to the master list
        if (currentList === `public_${listId}`) {
          setCurrentList('master');
          fetchMasterMovieList();
        }
  
        setNotification({ message: `Public list "${listName}" deleted successfully!`, type: 'success' });
      } catch (error) {
        console.error('Error deleting public list:', error);
        setNotification({ message: 'Failed to delete public list. Please try again.', type: 'error' });
      }
    }
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm('Are you sure you want to delete this movie?')) {
      await deleteMovieFromFirebase(id);
    }
  };

  const renderPublicLists = useCallback(() => {
    const handleDoubleClick = (listId, field) => {
      if (isAdmin) {
        setEditingField({ listId, field });
      }
    };
  
    const handleBlur = (listId, field, value) => {
      if (field === 'name') {
        handlePublicListNameChange(listId, value);
      } else if (field === 'author') {
        handlePublicListAuthorChange(listId, value);
      }
      setEditingField(null);
    };
  
    return isPublicListsOpen && publicLists.map((list) => (
      <li
        key={list.id}
        className="p-2 hover:bg-indigo-700 rounded cursor-pointer ml-4"
      >
        <div className="flex items-center justify-between">
          <div className="flex flex-col" onClick={() => handleShowPublicList(list.id)}>
            {editingField?.listId === list.id && editingField?.field === 'name' ? (
              <input
                type="text"
                defaultValue={list.name}
                onBlur={(e) => handleBlur(list.id, 'name', e.target.value)}
                autoFocus
                className="bg-white bg-opacity-10 border border-white border-opacity-20 rounded px-1 py-0.5 mr-1 text-white w-full"
              />
            ) : (
              <span 
                className="font-semibold"
                onDoubleClick={() => handleDoubleClick(list.id, 'name')}
              >
                {list.name}
              </span>
            )}
            {editingField?.listId === list.id && editingField?.field === 'author' ? (
              <input
                type="text"
                defaultValue={list.authorHandle}
                onBlur={(e) => handleBlur(list.id, 'author', e.target.value)}
                autoFocus
                className="bg-white bg-opacity-10 border border-white border-opacity-20 rounded px-1 py-0.5 mr-1 text-white text-sm w-full"
              />
            ) : (
              <span 
                className="text-sm text-gray-300"
                onDoubleClick={() => handleDoubleClick(list.id, 'author')}
              >
                {list.authorHandle || 'Anonymous'}
              </span>
            )}
          </div>
          {isAdmin && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleDeletePublicList(list.id, list.name);
              }}
              className="text-red-500 hover:text-red-700 ml-2"
            >
              <Trash2 size={18} />
            </button>
          )}
        </div>
      </li>
    ));
  }, [isPublicListsOpen, publicLists, isAdmin, editingField, 
    setEditingField, handlePublicListNameChange, handlePublicListAuthorChange, handleShowPublicList, handleDeletePublicList]);

  const handleEditSubmit = async () => {
    const updatedMovie = {
      ...editingMovie,
      title: manualMovieData.title,
      year: manualMovieData.year,
      country: manualMovieData.country,
    };
  
    const updatedMovies = recalculateWeights(
      movies.map(movie => movie.id === editingMovie.id ? updatedMovie : movie)
    );
  
    if (currentList === 'master' && isAdmin) {
      try {
        await updateMasterList(updatedMovies);
        setMovies(updatedMovies);
        setMasterList(updatedMovies);
        setNotification({ message: 'Movie updated in master list', type: 'success' });
      } catch (error) {
        console.error('Error updating movie in master list:', error);
        setNotification({ message: 'Failed to update movie in master list', type: 'error' });
      }
    } else {
      updateLocalChanges(currentList, updatedMovies);
      setMovies(updatedMovies);
    }
  
    setEditingMovie(null);
    setManualEntryMode(false);
    setManualMovieData({ title: '', year: '', country: '' });
    setHasUnsavedChanges(true);
  };

  const getImdbUrl = (imdbId) => `https://www.imdb.com/title/${imdbId}/`;

  const MoviePoster = ({ poster, title }) => {
    if (poster) {
      return <img src={poster} alt={title} className="w-16 h-24 object-cover rounded mr-4" />;
    } else {
      return (
        <div className="w-16 h-24 bg-gray-200 rounded mr-4 flex items-center justify-center">
          <Film size={32} className="text-gray-400" />
        </div>
      );
    }
  };

  
  const proceedWithSaving = () => {
    let newListName = prompt('Enter a name for this list:', 'My New List');
    if (newListName) {
      const isPublic = window.confirm('Do you want to make this list public?');
      saveUserData(newListName, isPublic);
    }
  };
  const decreaseMovieWeightInList = async (movieId, listRef) => {
    const snapshot = await get(listRef);
    const listData = snapshot.val();
    if (listData && listData.movies && listData.movies[movieId]) {
      const updatedWeight = listData.movies[movieId].weight - 1;
      if (updatedWeight > 0) {
        await update(child(listRef, `movies/${movieId}`), { weight: updatedWeight });
      } else {
        await remove(child(listRef, `movies/${movieId}`));
      }
    }
  };
  
  const updateMasterListOnDelete = async (movieId) => {
    const masterListRef = ref(database, 'movies');
    const masterSnapshot = await get(masterListRef);
    const masterMovies = masterSnapshot.val();
    if (masterMovies && masterMovies[movieId]) {
      const updatedWeight = masterMovies[movieId].weight - 1;
      if (updatedWeight > 0) {
        await update(child(masterListRef, movieId), { weight: updatedWeight });
      } else {
        await remove(child(masterListRef, movieId));
      }
      // Recalculate weights for master list
      const updatedMasterMovies = recalculateWeights(Object.values(masterMovies).filter(movie => movie.id !== movieId));
      await set(masterListRef, updatedMasterMovies.reduce((acc, movie) => ({...acc, [movie.id]: movie}), {}));
    }
  };
  
  

  const updatePublicListOnDelete = async (publicListId, movieId) => {
    const publicListRef = ref(database, `publicLists/${publicListId}`);
    const publicSnapshot = await get(publicListRef);
    const publicListData = publicSnapshot.val();
    if (publicListData && publicListData.movies && publicListData.movies[movieId]) {
      const updatedWeight = publicListData.movies[movieId].weight - 1;
      if (updatedWeight > 0) {
        await update(child(publicListRef, `movies/${movieId}`), { weight: updatedWeight });
      } else {
        await remove(child(publicListRef, `movies/${movieId}`));
      }
      // Recalculate weights for public list
      const updatedPublicMovies = recalculateWeights(Object.values(publicListData.movies).filter(movie => movie.id !== movieId));
      await set(child(publicListRef, 'movies'), updatedPublicMovies.reduce((acc, movie) => ({...acc, [movie.id]: movie}), {}));
    }
  };

  
  
  
  const handleSaveList = async () => {
    if (!isLoggedIn) {
      const wantsToLogin = window.confirm("Would you like to log in and save this list as a private copy?");
      if (wantsToLogin) {
        try {
          await handleAuth();
          if (!isLoggedIn) {
            throw new Error("Login failed");
          }
        } catch (error) {
          console.error("Login failed:", error);
          setNotification({ message: "Login failed. Changes will only be applied to the original list.", type: "warning" });
          return;
        }
      }
    }
  
    await saveCurrentList();
    setHasUnsavedChanges(false);
    console.log('List saved');
  };

  const handleManualSubmit = () => {
    const { title, year, country, language } = manualMovieData;
    const missingFields = [];
  
    if (!title) missingFields.push('Title');
    if (!year) missingFields.push('Year');
    if (!country) missingFields.push('Country');
    if (!language) missingFields.push('Language');
  
    if (missingFields.length > 0) {
      setNotification({
        message: `Please fill in the following required fields: ${missingFields.join(', ')}`,
        type: 'error'
      });
      return;
    }
  
    const newMovie = {
      ...manualMovieData,
      id: `movie_${Date.now()}`,
      weight: 1,
    };
  
    addMovie(newMovie);
    setIsManualEntry(false);
    setManualMovieData({ title: '', year: '', country: '', language: '' });
  };

  
 
  const makeListPublic = async (listId) => {
    try {
      const userListRef = ref(database, `users/${user.uid}/lists/${listId}`);
      const userListSnapshot = await get(userListRef);
      const userListData = userListSnapshot.val();
  
      if (!userListData) {
        throw new Error("List not found");
      }
  
      // Create a new public list
      const publicListRef = push(ref(database, 'publicLists'));
      const publicListId = publicListRef.key;
      await set(publicListRef, {
        name: userListData.name,
        author: user.uid,
        authorHandle: userHandle,
        movies: userListData.movies
      });
  
      // Update the user's private list with the public list ID
      await update(userListRef, { publicListId: publicListId });
  
      setNotification({ message: "List made public successfully!", type: "success" });
      
      // Update local state
      setPublicLists(prevLists => [
        ...prevLists,
        { id: publicListId, name: userListData.name, authorHandle: userHandle }
      ]);
  
    } catch (error) {
      console.error("Error making list public:", error);
      setNotification({ message: "Failed to make list public. Please try again.", type: "error" });
    }
  };

  const updateOriginalList = async () => {
    const isPublicList = currentList.startsWith('public_');
    const listRef = ref(database, isPublicList 
      ? `publicLists/${currentList.replace('public_', '')}/movies` 
      : 'movies');
  
    const originalSnapshot = await get(listRef);
    const originalMovies = originalSnapshot.val() || {};
  
    const updates = {};
    movies.forEach(movie => {
      if (originalMovies[movie.id]) {
        // Update existing movie weight
        const weightDiff = movie.weight - (originalMovies[movie.id].weight || 0);
        updates[`${movie.id}/weight`] = (originalMovies[movie.id].weight || 0) + weightDiff;
      } else {
        // Add new movie
        updates[movie.id] = { ...movie };
      }
    });
  
    await update(listRef, updates);
  
    if (currentList === 'master') {
      setMasterList(prevMasterList => {
        const updatedMasterList = [...prevMasterList];
        Object.keys(updates).forEach(movieId => {
          const index = updatedMasterList.findIndex(m => m.id === movieId);
          if (index !== -1) {
            updatedMasterList[index] = { ...updatedMasterList[index], ...updates[movieId] };
          } else {
            updatedMasterList.push(updates[movieId]);
          }
        });
        return updatedMasterList.sort((a, b) => b.weight - a.weight);
      });
    }
  
    setNotification({ message: "Original list updated successfully!", type: "success" });
  };

   // Modify the existing sidebar to include the delete button for user lists
   const renderUserLists = useCallback(() => {
    return userLists.map(list => (
      <li 
        key={list.id} 
        className="ml-4 p-2 hover:bg-indigo-700 rounded cursor-pointer flex justify-between items-center"
      >
        <span onClick={() => handleShowUserList(list.id)} className="flex items-center">
          {list.name}
          {list.publicListId && (
            <Globe size={16} className="ml-2 text-blue-300" title="Linked to a public list" />
          )}
        </span>
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteList(list.id, list.name);
          }}
          className="text-red-500 hover:text-red-700"
        >
          <Trash2 size={18} />
        </button>
      </li>
    ));
  }, [userLists, handleShowUserList, handleDeleteList]);

  const renderMovieItem = useCallback((movie, index) => (
    <Draggable key={movie.id} draggableId={movie.id} index={index}>
      {(provided, snapshot) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`bg-white rounded-lg shadow-md p-4 flex items-center ${
            snapshot.isDragging ? 'bg-gray-100' : ''
          }`}
        >
          <div className="w-12 sm:w-16 text-center mr-2 sm:mr-4">
            <span className="font-bold text-lg sm:text-xl">{index + 1}</span>
            <br />
            <span className="text-xs text-gray-500">
              ({movie.weight || 'N/A'})
            </span>
          </div>
          <MoviePoster poster={movie.poster} title={movie.title} />
          <div className="flex-grow">
            <div className="flex flex-col sm:flex-row sm:items-center">
              <h3 className="font-semibold text-base sm:text-lg flex-grow mb-2 sm:mb-0">
                <a 
                  href={getImdbUrl(movie.imdbId)}
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 hover:underline"
                >
                  {movie.title} ({movie.year || 'N/A'})
                  <ExternalLink size={16} className="inline ml-1 mb-1" />
                </a>
              </h3>
              <div className="flex">
                <button
                  onClick={() => handleEditClick(movie)}
                  className="text-blue-500 hover:text-blue-700 mr-2"
                >          
                  <Edit size={18} />
                </button>
                <button
                  onClick={() => handleDeleteMovie(movie.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  <Trash2 size={18} />
                </button>
              </div>
            </div>
            <p className="text-sm text-gray-600">
              Country: {movie.country || 'N/A'} | Language: {movie.language || 'N/A'}
            </p>
          </div>
        </li>
      )}
    </Draggable>
  ), [handleEditClick, handleDeleteMovie]);
  
  const NewListButton = () => {
    if (!isLoggedIn || isAdmin) return null;

    return (
      <button 
        onClick={handleNewList}
        className="px-3 py-1 bg-indigo-600 text-white rounded hover:bg-indigo-700 flex items-center"
      >
        <Plus size={20} />
        <span className="ml-2 hidden sm:inline">New List</span>
      </button>
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex flex-col h-screen bg-gray-100 relative">
        {notification && (
          <Notification
            message={notification.message}
            type={notification.type}
            onClose={() => setNotification(null)}
          />
        )}
        
        <AboutModal isOpen={isAboutModalOpen} onClose={() => setIsAboutModalOpen(false)} />
        
        {isInitialLoading ? (
          <div className="flex-1 flex items-center justify-center flex-col">
            <div className="text-lg font-semibold">{loadingStage}</div>
            <p className="mt-2">Please wait while the application loads...</p>
          </div>
        ) : (
          <>
            <header className="bg-indigo-800 text-white p-4 flex justify-between items-center">
              <div className="flex items-center">
                <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="lg:hidden">
                  <Menu size={24} />
                </button>
                <h1 className="text-xl font-bold ml-2">Movie List App</h1>
              </div>
              
              <div className="flex items-center space-x-2">
                <NewListButton />
                
                {/* Update Points button for admin */}
                    {isAdmin && (
                      <button 
                        onClick={handleUpdatePoints}
                        className="px-3 py-1 bg-green-600 text-white rounded hover:bg-green-700 flex items-center"
                      >
                        <RefreshCw size={20} />
                        <span className="ml-2 hidden sm:inline">Update Points</span>
                      </button>
                    )}
                      
                      <button 
                        onClick={() => setIsAboutModalOpen(true)}
                        className="px-3 py-1 bg-indigo-600 text-white rounded hover:bg-indigo-700 flex items-center"
                      >
                        <Info size={20} />
                        <span className="ml-2 hidden sm:inline">About</span>
                      </button>
                      
                      {isLoggedIn && (
                        <span className="mr-4 text-sm hidden sm:inline">
                          {userHandle}
                        </span>
                      )}
                      
                      <button 
                        onClick={handleAuth}
                        className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 flex items-center"
                      >
                        {isLoggedIn ? <LogOut size={20} /> : <LogIn size={20} />}
                        <span className="ml-2 hidden sm:inline">{isLoggedIn ? 'Logout' : 'Login'}</span>
                      </button>
                    </div>
                  </header>

                  <div className="flex flex-1 overflow-hidden">
                  {/* Sidebar */}
              <div 
                className={`${
                  isSidebarOpen ? 'block' : 'hidden'
                } lg:block w-64 bg-indigo-800 text-white p-6 absolute lg:relative z-10 h-full overflow-y-auto`}
              >
                <button onClick={() => setIsSidebarOpen(false)} className="lg:hidden absolute top-4 right-4">
                  <X size={24} />
                </button>
                <ul className="space-y-2 mt-8 lg:mt-0">
                  <li 
                    className="flex items-center space-x-2 p-2 hover:bg-indigo-700 rounded cursor-pointer"
                    onClick={handleShowMasterList}
                  >
                    <List size={20} />
                    <span>Show Master List</span>
                  </li>
                  {isLoggedIn && (
                    <>
                      <li className="flex items-center space-x-2 p-2 hover:bg-indigo-700 rounded cursor-pointer" onClick={toggleMyLists}>
                        <List size={20} />
                        <span>My Lists</span>
                        {isMyListsOpen ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                      </li>
                      {renderUserLists()}
                    </>
                  )}
                  <li className="flex items-center space-x-2 p-2 hover:bg-indigo-700 rounded cursor-pointer" onClick={togglePublicLists}>
                    <Globe size={20} />
                    <span>Public Lists</span>
                    {isPublicListsOpen ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                  </li>
                  {renderPublicLists()}
                </ul>
              </div>

              {/* Main Content */}
              <div className="flex-1 flex flex-col overflow-hidden relative">
                <div className="flex-1 flex flex-col min-h-0">
                  <h2 className="text-2xl font-bold p-4">
                    {getListName(currentList)}
                  </h2>

                  {hasUnsavedChanges && (
                    <button
                      onClick={handleSaveList}
                      className="fixed right-4 top-20 px-6 py-3 bg-green-500 text-white rounded-full shadow-lg hover:bg-green-600 transition-colors duration-300 flex items-center z-50"
                    >
                      <Save size={24} className="mr-2" />
                      Save List
                    </button>
                  )}

                  {/* Movie List */}
                  <Droppable droppableId="movieList">
                    {(provided) => (
                      <div className="flex-1 overflow-hidden">
                        <div className="h-full overflow-y-auto pb-32">
                          {isListRefreshing && (
                            <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center z-10">
                              <div className="text-gray-600">Updating list...</div>
                            </div>
                          )}
                          <ul 
                            {...provided.droppableProps} 
                            ref={provided.innerRef}
                            className="p-4 space-y-4"
                          >
                            {movies && movies.length > 0 ? (
                              movies.map((movie, index) => renderMovieItem(movie, index))
                            ) : (
                              <li className="text-xl text-gray-600 text-center">
                                {isListRefreshing ? "Loading..." : "No movies in this list."}
                              </li>
                            )}
                            {provided.placeholder}
                          </ul>
                        </div>
                      </div>
                    )}
                  </Droppable>

                  {/* Search Bar and Results */}
                  {isFirebaseLoaded && (
                    <div className="sticky bottom-0 left-0 right-0 bg-gray-200 border-t border-gray-300 shadow-lg z-50">
                      <div className="p-4">
                        {!isManualEntry ? (
                          <div className="relative">
                            {/* Search Results Dropdown - Positioned above the input */}
                            <div className="absolute bottom-full left-0 right-0 mb-2">
                              {pendingSearch && (
                                <div className="bg-white p-2 border rounded-md shadow-lg text-gray-600">
                                  Loading...
                                </div>
                              )}

                              {suggestions.length > 0 && (
                                <ul className="bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto">
                                  {suggestions.map((movie) => (
                                    <li
                                      key={movie.imdbID}
                                      className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
                                      onClick={() => addMovie(movie)}
                                    >
                                      <PlusCircle size={16} className="mr-2 text-green-500" />
                                      <span>{movie.Title} ({movie.Year})</span>
                                    </li>
                                  ))}
                                </ul>
                              )}

                              {!pendingSearch && 
                               hasSearched && 
                               suggestions.length === 0 && 
                               searchTerm && (
                                <div className="bg-white border rounded-md shadow-lg p-2">
                                  <button
                                    onClick={() => setIsManualEntry(true)}
                                    className="w-full p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                                  >
                                    Movie not found? Add manually
                                  </button>
                                </div>
                              )}
                            </div>

                            {/* Search Input */}
                            <div className="relative">
                              <input
                                ref={searchInputRef}
                                type="text"
                                value={searchTerm}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                placeholder="Search for a movie (press Enter to search immediately)"
                                className="w-full p-2 pr-10 border rounded-md"
                                autoFocus
                              />
                              <Search className="absolute right-3 top-2.5 text-gray-400" size={20} />
                            </div>
                          </div>
                        ) : (
                          <div>
                            <input
                              type="text"
                              value={manualMovieData.title}
                              onChange={(e) => setManualMovieData({...manualMovieData, title: e.target.value})}
                              placeholder="Movie Title *"
                              className="w-full p-2 mb-2 border rounded-md"
                            />
                            <input
                              type="text"
                              value={manualMovieData.year}
                              onChange={(e) => setManualMovieData({...manualMovieData, year: e.target.value})}
                              placeholder="Year *"
                              className="w-full p-2 mb-2 border rounded-md"
                            />
                            <input
                              type="text"
                              value={manualMovieData.country}
                              onChange={(e) => setManualMovieData({...manualMovieData, country: e.target.value})}
                              placeholder="Country *"
                              className="w-full p-2 mb-2 border rounded-md"
                            />
                            <input
                              type="text"
                              value={manualMovieData.language}
                              onChange={(e) => setManualMovieData({...manualMovieData, language: e.target.value})}
                              placeholder="Language *"
                              className="w-full p-2 mb-2 border rounded-md"
                            />
                            <div className="flex space-x-2">
                              <button
                                onClick={handleManualSubmit}
                                className="flex-1 p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                              >
                                Add Movie
                              </button>
                              <button
                                onClick={() => {
                                  setIsManualEntry(false);
                                  setManualMovieData({ title: '', year: '', country: '', language: '' });
                                }}
                                className="flex-1 p-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </DragDropContext>
  );
};

export default MovieList;